import 'whatwg-fetch'
import PosabitApp from './lib/app'
import PosabitFrame from './lib/frame'
import PosabitWidget from './lib/widget'
  ;(function (document, window) {
  'use strict'

  // Widget settings object
  const appConfig = window.posabitmenuwidget

  // Convenience methods
  const widget = new PosabitWidget(appConfig)
  const app = new PosabitApp(appConfig)

  // Ensure everything is configured and giddy up
  if (widget.isConfigured() && widget.hasTargetElement()) {
    if (appConfig.config.iframe) {
      const iframe = new PosabitFrame(appConfig)
      iframe.embed()
    } else {
      app.authAndMount()
    }
  }
})(document, window)
