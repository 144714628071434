import PosabitCookie from './cookie'

export default class PosabitApp {
  constructor (settings = {}) {
    this.settings = settings
    this.cookie = new PosabitCookie(
      `posabit-config-${this.settings.config.api_key}`,
      1 / 96 // expiration in days (96 = 15min)
    )

    const currentScript = document.currentScript
    this.baseUri = new URL(currentScript.src).hostname
  }

  /**
   * Verify authorization and mount
   */
  authAndMount () {
    if (this.isCached()) {
      return this.mount()
    }

    const self = this

    // callback to verify host and venue
    // success caches response in a cookie
    this.requestAuthorization(this.baseUri)
      .then(response => {
        if (response.ok) {
          return response.json()
        }

        return response.json().then(json => {
          throw new Error(json.error_msg || response.statusText)
        })
      })
      .then(json => {
        self.cookie.set(json.venue_info)
        self.mount()
      })
      .catch(err => {
        console.warn(err)
      })
  }

  /**
   * Verify client domain is allowed to embed the menu
   *
   * @TODO polyfill for fetch() request?
   *
   * @returns {Promise} Fetch request promise
   */
  requestAuthorization (uri) {
    const { config } = this.settings

    return fetch(`https://${uri}/allowed_hosts.php`, {
      method: 'POST',
      body: JSON.stringify({
        host:
          window.self === window.top
            ? window.location.host
            : new URL(document.referrer).hostname,
        api_key: config.api_key
      })
    })
  }

  /**
   * Check if we've cached an ok auth
   */
  isCached () {
    const venueInfo = this.cookie.get()
    if (venueInfo) {
      window.posabitVenueInfo = venueInfo ? JSON.parse(venueInfo) : false
      return true
    }

    return false
  }

  /**
   * Load app script and style
   */
  mount () {
    const insertLocation = document.getElementsByTagName('script')[0]

    const appScript = document.createElement('script')
    appScript.async = true
    appScript.src = `https://${this.baseUri}/js/app.js`
    insertLocation.parentNode.insertBefore(appScript, insertLocation)

    const appStyle = document.createElement('link')
    appStyle.rel = 'stylesheet'
    appStyle.href = `https://${this.baseUri}/css/app.css`
    insertLocation.parentNode.insertBefore(appStyle, insertLocation)
  }
}
