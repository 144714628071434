export default class PosabitWidget {
  constructor (settings = {}) {
    this.settings = settings
  }

  /**
   * Check if app is properly configured
   *
   * @returns {boolean}
   */
  isConfigured () {
    const { config } = this.settings

    if (!config || !config.feed_id || !config.api_key) {
      console.warn('Please pass a configuration to the POSaBIT menu widget')
      return false
    }

    return true
  }

  /**
   * make sure the mounting div exists as configured, or create it.
   */
  hasTargetElement () {
    const { config } = this.settings

    if (config.element_id) {
      return this.maybeCreateAppDiv(config.element_id)
    }

    return this.maybeCreateAppDiv('app')
  }

  /**
   * Checks dom for ID, creates it if it doesn't exist.
   * @param {string} id mount div id to look for or create
   */
  maybeCreateAppDiv (id) {
    let mountDiv = document.getElementById(id)

    if (mountDiv) {
      return mountDiv
    }

    // create and inject near here
    mountDiv = document.createElement('div')
    mountDiv.id = id

    return this.insertNearThisScript(mountDiv)
  }

  /**
   * Insert a dom element near the location of this script. or element with `id="pab-menu-script"`
   * @param {Node} element element to inseart
   */
  insertNearThisScript (element) {
    const insertLocation = document.getElementById('pab-menu-script')
    return insertLocation.parentNode.insertBefore(element, insertLocation)
  }
}
