import Cookies from 'js-cookie'

export default class PosabitCookie {
  constructor (name, duration) {
    this.cookieName = name
    this.duration = duration
  }

  set (data) {
    return Cookies.set(this.cookieName, data, {
      expires: this.duration
    })
  }

  get () {
    return Cookies.get(this.cookieName)
  }
}
