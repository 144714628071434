export default class PosabitFrame {
  constructor (settings = {}) {
    this.settings = settings

    const url = document.currentScript
    this.hostname = new URL(url.src).hostname
  }

  /**
   * Embed iframe version of app
   */
  embed () {
    const { config } = this.settings
    const queryParam = encodeURIComponent(JSON.stringify(this.settings))
    const uri = `https://${this.hostname}/frame.html?config=${queryParam}`

    const frameID = `pab-menu-frame-${config.feed_id}`
    const frame = document.createElement('iframe')
    frame.src = uri
    frame.width = '100%'
    frame.style.minHeight = '420px'
    frame.style.width = '100px'
    frame.style.minWidth = '100%'
    frame.id = frameID
    frame.frameBorder = 0

    // iframe resize
    const frameClient = document.createElement('script')
    const insertLocation = document.getElementsByTagName('script')[0]
    frameClient.src = `https://${this.hostname}/js/iframeClient.js`
    insertLocation.parentNode.insertBefore(frameClient, insertLocation)

    let targetLocation = document.getElementById(config.element_id)
    if (!targetLocation) {
      targetLocation = document.getElementById('app')
    }

    targetLocation.appendChild(frame)

    frameClient.addEventListener('load', function () {
      window.iFrameResize({ checkOrigin: false }, `#${frameID}`)
    })
  }
}
